import { post,request } from '../../utils/request.js'
// 登录
export function login(phone, pwd) {
    return post('/api/interlist/person/Onlogin', {
        phone: phone,
        password: pwd
    })
}

// 获取站点信息
export function getRegion() {
    return request({
        url: '/api/Receivepolice/get_AllRegionId'
    })
}

//获取车辆
export function getCarsByRid(rid) {
    return request({
        url: '/api/person/getAllCars?regionId='+rid
    })
}

//获取摄像头设备状态
export function getOnlineStatus(session,devId) {
    return request({
        url: '/api/third/getDeviceOlStatus?jsession='+session+'&devIdno='+devId
    })
}

//获取摄像头设备状态
export function getGpsStatus(session,devId) {
    return request({
        url: '/api/third/getDeviceStatus?jsession='+session+'&devIdno='+devId
    })
}


//云台
export function control(session,devId,command) {
    return request({
        url: '/api/third/sendPTZControl?jsession='+session+'&devIdno='+devId+'&command='+command
    })
}