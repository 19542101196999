<template>
	<div>
	<div class="background">
		<img :src="imgSrc" width="100%" height="100%" alt="" />
	</div>
	<div class="page">
		<div class="tops">
			<!-- <h3><img src="../assets/images/logtit.png"></h3> -->
		</div>
		<div class="lgbox" :model="form">
			<div class="aui-flex">
				<div class="aui-flex-box">
					<i class="icon icon-user"></i>
					<input id="user" type="text" placeholder="帐号:" v-model="form.phone">
				</div>
			</div>
			<div class="aui-flex">
				<div class="aui-flex-box">
					<i class="icon icon-pwd"></i>
					<input id="password" type="password" placeholder="密码:" v-model="form.password">
				</div>
			</div>
		</div>
		<div class="weui-form_area" @click="login">登录</div>
	</div>
	</div>
</template>
<script>
	import {
		mapMutations
	} from 'vuex';
	import {
		login
	} from '../js/index.js'
	export default {
		data() {
			return {
				imgSrc: require('../assets/images/img22.png'),
				form: {
					phone: '',
					password: ''
				},
			}
		},
		methods: {
			...mapMutations(['changeLogin']),
			login: function() {
				let _this = this;
				if (this.form.phone === '') {
					this.$message({
						message: '请输入帐号!',
						type: 'error',
						showClose: true,
						center: true,
						duration: 3000,
					});
					return;
				}
				if (this.form.password === '') {
					this.$message({
						message: '请输入密码!',
						type: 'error',
						showClose: true,
						center: true,
						duration: 3000,
					});
					return;
				} else
					login(this.form.phone,this.form.password)
					.then(res => {
						// const data = res.data
						console.log(res.data.uid)
						_this.token = res.data.uid;
						// 将用户token保存到vuex中
						_this.changeLogin(res.data.uid);
						if (res.code === 200) {
							this.$message({
								message: '登录成功!',
								type: 'success',
								showClose: true,
								center: true,
								duration: 3000,
							});
							setTimeout(() => {
								this.$router.push({
									path: '/map'
								});
							}, 2000);
						} else {
							alert('登陆失败')
						}
					}).catch(err => console.log(err))
			}
		},


	}
</script>
<style scoped>
	.background {
		width: 100%;
		height: 100%;
		z-index: -1;
		position: absolute;
	}

	.tops {
		text-align: center;
		padding: 90px 0px 40px;
	}

	.lgbox {
		width: 38%;
		margin: 70px auto 0;
		background: url(../assets/images/img17.png) 0px 2px no-repeat, url(../assets/images/img18.png) 695px 1px no-repeat, url(../assets/images/img21.png) -10px -17px no-repeat, url(../assets/images/img19.png) 0px 338px no-repeat, url(../assets/images/img20.png) 697px 340px no-repeat rgba(8, 33, 46, 0.6);
		border-radius: 20px;
		padding: 80px 110px;
	}

	.aui-flex {
		margin: 40px 0px;
		position: relative;
	}

	.aui-flex-box {
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		flex: 1;
		min-width: 0;
		font-size: 14px;
		color: #333;
	}

	.icon-user {
		position: absolute;
		left: 18px;
		top: 16px;
		background: url(../assets/images/img15.png) no-repeat;
	}

	.icon-pwd {
		position: absolute;
		left: 18px;
		top: 16px;
		background: url(../assets/images/img16.png) no-repeat;
	}

	.icon {
		width: 20px;
		height: 20px;
		border: none;
		background-size: 20px;
		background-repeat: no-repeat;
	}

	.aui-flex-box input {
		border: 1px solid #09355d;
		width: 100%;
		color: #17C4BF;
		height: 55px;
		font-family: arial;
		padding: 0px 0px 0px 50px;
		background: rgba(8, 33, 46, 0.5);
		border-radius: 30px;
		font-size: 18px;
	}

	input::-webkit-input-placeholder {
		color: #17C4BF;
	}

	.weui-form_area {
		width: 38%;
		margin: 50px auto 0px;
		background: -webkit-linear-gradient(0deg, #0E9AC2 0%, #16F5E6 100%);
		/* Safari 5.1 - 6.0 */
		background: -o-linear-gradient(0deg, #0E9AC2 0%, #16F5E6 100%);
		/* Opera 11.1 - 12.0 */
		background: -moz-linear-gradient(0deg, #0E9AC2 0%, #16F5E6 100%);
		/* Firefox 3.6 - 15 */
		background: linear-gradient(0deg, #0E9AC2 0%, #16F5E6 100%);
		border-radius: 50px;
		padding: 15px 0px;
		text-align: center;
		font-size: 28px;
		color: #fff;
		font-weight: bold;
		cursor: pointer;
	}
</style>
